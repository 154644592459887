import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChat } from '../contexts/ChatContext';
//import { useAuth } from '../contexts/AuthContext';
import { useSiteAuth } from '../contexts/SiteAuthContext'; // Use SiteAuthContext for    authentication
import { ListingDetailsContext } from '../contexts/ListingDetailsContext';
import { useCarouselImages } from '../contexts/SiteCarouselContext';
import styles from '../styles/WelcomePage.module.css';
import axios from 'axios';
import styled from 'styled-components';
import StaticButtonGame from './StaticButtonGame';
import RenderImageBubbleGame from './RenderImageBubbleGame';
import styles_bg from '../styles/CallBubbleGame.module.css';
import WelcomePageModal from './WelcomePageModal';
import SiteLoginSignUp from './SiteLoginSignUp';

const base_url = 'https://hbbreact.blob.core.windows.net/hbbblob2/REAL_ESTaiTE/';


const ImageContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const StyledImage = styled.img`
  max-width: 60%;
  height: auto;
  max-height: 400px; /* Adjust this value as needed */
  object-fit: cover;
`;

function WelcomePage() {
  const [balloonQuestions, setBalloonQuestions] = useState([]);	
  const [user, setUser] = useState(null); // State to store user information
  const [isLogoImageOneLoaded, setIsLogoImageOneLoaded] = useState(true);
  const [isLogoImageTwoLoaded, setIsLogoImageTwoLoaded] = useState(true); 
  const [defaultListing, setDefaultListing] = useState(false);
  const [answers, setAnswers] = useState({});
  const [loginStatus, setLoginStatus] = useState("");
  const { listingDetails } = useContext(ListingDetailsContext);
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [firstName, setFirstName] = useState(localStorage.getItem('firstName') || '');
  const [lastName, setLastName] = useState(localStorage.getItem('lastName') || '');
  const [phone, setPhone] = useState(localStorage.getItem('phone') || '');
  const [password, setPassword] = useState('');
  const [representedByAgent, setRepresentedByAgent] = useState(null);
	

  const navigate = useNavigate();
  const [view, setView] = useState('questions'); // questions, anonymous, signup, signin
  const [userId, setUserId] = useState('');
  const { 
    context_chatLog, 
    context_addMessageToChatLog, 
    context_systemPrompt, 
    context_setSystemPrompt, 
    context_userPrompt, 
    context_setUserPrompt, 
    context_chatId, 
    context_setChatId, 
    context_questionId, 
    context_setQuestionId, 
    context_siteLocation, 
    context_setSiteLocation, 
    context_ConvoTop, 
    context_setConvoTop, 
    context_showUser, 
    context_setShowUser, 
    context_showAgent, 
    context_setShowAgent, 
    context_context, 
    context_setContext,
    context_listing_id,        
    context_set_listing_id,
	context_logUserInteraction
  } = useChat();	

  const [isModalOpen, setIsModalOpen] = useState(true); // Modal opens on page load
  const [showButton, setShowButton] = useState(false); // State to manage the button visibility	
  const [navPrompt, setNavPrompt] = useState(''); // State to manage the button visibility	
	
  const { loadImageData, imagesLoaded } = useCarouselImages();
  const [carouselImages, setCarouselImages] = useState([]);
  const [homeSystemPrompt, setHomeSystemPrompt] = useState('');
  const [isReadyToRender, setIsReadyToRender] = useState(false);
	
  // Use effect to check when listingDetails is available1
  useEffect(() => {
    if (listingDetails && Object.keys(listingDetails).length > 0) {
      setIsReadyToRender(true); // Set ready to render when listingDetails is fetched
      console.log('listingDetails', listingDetails);

    }
  }, [listingDetails]);	
	
	// Load images when the component mounts
  useEffect(() => {
    const fetchImages = async () => {
      const images = await loadImageData('some-carousel-type'); // Fetch images for a particular carousel type
      setCarouselImages(images); // Store fetched images

      // Find the first image where image_tile_destination is 'home'
      const homeImage = images.find(img => img.image_tile_destination === 'home');

      if (homeImage) {
        // Log the image_click_system_prompt for that image
        setHomeSystemPrompt(homeImage.image_click_system_prompt);
      } else {
        console.log('No image found with destination "home"');
      }
    };

    fetchImages();
  }, [loadImageData]);
	
  // Handle Login From Site Auth Context 	
  const {siteUser,
        siteIsLoggedIn,
	    siteIsAdmin,
        siteLogin,
        siteSignup,
        siteLogout,
        siteLoading} = useSiteAuth([])

  // This useEffect will run whenever the login status or admin status changes
//  useEffect(() => {
//    console.log('CHECKING siteIsLoggedIn', siteIsLoggedIn);
//    if (siteIsLoggedIn) {
//	  console.log('CHECKING siteIsAdmin', siteIsAdmin);
//      if (siteIsAdmin) {
//        navigate('/ListingAdmin');
//      } else {
//        navigate('/ListingAdmin');
//      }
//    } else {
//	  console.log('CHECKING NOT LOGGED IN');
//      navigate('/siteLogin');
//    }
//  }, [siteIsLoggedIn, siteIsAdmin]);	
	
//  useEffect(() => {
//    if (listingDetails && Object.keys(listingDetails).length > 0) {
//    } else {
//    }
//  }, [listingDetails]);
	
	
  useEffect(() => {
    setIsModalOpen(true);
	context_setSystemPrompt('INTRO');
    context_setUserPrompt('Tell me abou the site');
    context_setQuestionId('OpenModal');
  }, []);	
	
	
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowButton(true); 
  };	

  useEffect(() => {
    localStorage.setItem('email', email);
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('phone', phone);
  }, [email, firstName, phone]);	
  
  const handleAnswerChange = (question, answer) => {
    setAnswers(prev => ({
      ...prev,
      [question]: answer
    }));
  };
	
  const handleImageBubbleGameAnswersSelected = (question, answer) => {
//    console.log('WELCOME PAGE: Selected Question:', question);  
//    console.log('WELCOME PAGE: User Answer:', answer);          
	  
    // Call context_logUserInteraction to log the user interaction
    context_logUserInteraction(question, answer, 'imagebubblegame');	  
  };
	
//  const handleKeyDown = (e) => {
//    if (e.key === 'Enter') {
//      handleLogin('known', email, phone, userId, password, firstName, answers);
//    }
//  };	

	
	
//  const handleLogin = async (loginType, email, phone, userId, password, firstName, answers) => {
//    const check_status = await login(loginType, email, phone, userId, password, firstName, answers);
//    setLoginStatus(check_status.message); // Set the login status message
//    if (check_status.success) {
//	  navigateToDetail();
//    } else {
//      alert('Login failed.');
//    }
//  };
	
//  const handleSignup = async (loginType, firstName, lastName, email, phone, userId, password, answers, representedByAgent) => {
//	if (!firstName) {
//      alert('Please supply your name.');
//	  return;
//     }		
//	  
//    if (!representedByAgent) {
//      alert('Please select if you are represented by a buyer\'s agent.');
//      return;
//    }
////	console.log('ANSWERS FROM SignUp:', answers);
//	if (loginType !== 'anon') {
//		if (!validateEmail(email)) {
//		  alert('Please enter a valid email address.');
//		  return;
//		}
//		if (!password) {
//		  alert('Please enter a password.');
//		  return;
//		}	  
//		
//	} else {
//		
//	} 
//	  
//	  
//    const check_status = await login(loginType, email, phone, userId, password, firstName, answers, representedByAgent);
//    setLoginStatus(check_status.message); // Set the login status message
//    if (check_status.success) {
//	  navigateToDetail();
//    } else {
//      alert('Login failed.');
//    }
//  };
	

	
	
	
	
  const handleLogout = () => {
    siteLogout();
  };

//  if (!listingDetails || balloonQuestions.length === 0) {
//    return <div>Loading...</div>; // Show a loading state while data is being fetched
//  } else {
//  }
  if (!listingDetails) {
    return <div>Loading...</div>;
  } else {
  }
  
  const navigateTo = (path) => {
    navigate(path);
  };
  const handleNavButtonClick = async (buttonName) => {
    if (buttonName === 'admin') {
      try {
        console.log('Listing ID reset successfully in parent component!');
        navigate('/admin-console');
      } catch (error) {
        console.error('Failed to reset the Listing ID:', error);
      }
    } else  {
	    console.log('homeSystemPrompt', homeSystemPrompt);
	    context_setSystemPrompt(homeSystemPrompt);
        context_setUserPrompt('Please provide me some details on this home');
        context_setQuestionId('Home');
	    context_setSiteLocation('image detail');
	    context_setConvoTop('');
        navigate('/WrapperWithCarouselAndBinImageQGame');
		
    }
  };  

  const navigateToCarousel = () => {
    const userType = user && user.type ? user.type : '0';
    navigate('/MainCarouselWrapper', { state: { userType } });
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };
	
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };	
	
  if (!isReadyToRender) {
    return <div>Loading...</div>;
  }	
	

  return (
   <div className={styles.pageWrapper}>	
    {/* ChatModal Component */}
    <WelcomePageModal
      isOpen={isModalOpen}
      onClose={handleCloseModal} 
      chatLog={[]}  
      collapseCarousel={() => {}}  
      showFull={false}  
	  isModal={true}
      showLabels = {false}
    />	  
		  
    {/* Page content after modal is dismissed */}
    {!isModalOpen && (
      <>		  
		<div className={styles.welcomeContainer} style={{ background: 'white' }}>
		  <div className={styles.centeredContainer}>
			  <div className={styles.welcomelogoContainer}>
				<div className={styles.logoContainer}>
				  {isLogoImageOneLoaded && (
					<img
					  src={listingDetails.logoImageOne}
					  alt="Logo"
					  className={styles.logoImage}
					  onLoad={() => setIsLogoImageOneLoaded(true)}
					  onError={() => setIsLogoImageOneLoaded(false)}
					/>
				  )}
				</div>
				<div className={styles.logoContainer}>
				  {isLogoImageTwoLoaded && (
					<img

					  src={listingDetails.logoImageTwo}
					  alt="Second Logo"
					  className={styles.logoImage}
					  onLoad={() => setIsLogoImageTwoLoaded(true)}
					  onError={() => setIsLogoImageTwoLoaded(false)}
					/>
				  )}
				</div>
			  </div>	  
			<div className={styles.welcomeimageContainer}>
			  <img className={styles.welcomestyledImage} src={listingDetails.listingImage} alt="Home" />
			  <p>Agent: {listingDetails.listing_agent_name}</p>
			  <p>Description: {listingDetails.listing_description}</p>
			</div>
		  </div>    
		  <div className={styles.welcomebuttonContainer} style={{ textAlign: 'center' }}>
			{siteIsLoggedIn && siteIsAdmin && (
			  <React.Fragment>
				<button onClick={() => navigateToCarousel()} className={styles.welcomelink}>Carousel</button>
				<button onClick={handleLogout} className={styles.welcomelink}>Logout</button>
				<button onClick={() => navigate('/manage_listing', { state: { userType: '0' } })} className={styles.welcomelink}>Manage Listing</button>
			  </React.Fragment>
			)}

		  </div>
		  <div className={'styles.bubbleGameContainer'} >
			<div className={styles.cbg_mainContainer}>
			  <section className={styles.cbg_gameSection}>
				<RenderImageBubbleGame onImageBubbleGameAnswersSelected={handleImageBubbleGameAnswersSelected} />
			  </section>
			</div>

		  </div>
		  <div className={styles.loginsignupcontainer}>
		    <SiteLoginSignUp 
				currentView="signup" 
				onNavButtonClick={handleNavButtonClick}
				navButtonName="Enter" 
			/>
		  </div>
		</div>
        </>
      )}
   </div>
  );
};


export default WelcomePage;
