import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSiteAuth } from '../contexts/SiteAuthContext';

const LandingPage = () => {
  const {
    siteIsLoggedIn,
    siteListingIdFound,
    idFromURL,
    fetchListingId,
    siteLogout
  } = useSiteAuth();
  const navigate = useNavigate();

  // Step 1: Create local states to track fetched values
  const [sessionData, setSessionData] = useState({
    siteIsLoggedIn: false,
    siteListingIdFound: false,
    idFromURL: false,
  });

  useEffect(() => {
    console.log('LANDING PAGE initializePage');

    const initializePage = async () => {
      // Step 2: Fetch the latest listing ID from session and use the returned values directly
      const { listingId, siteListingIdFound, idFromURL } = await fetchListingId();
      console.log('Fetched Session Data:', {
        siteIsLoggedIn,
        siteListingIdFound,
        idFromURL,
      });

      // Step 3: Use returned values to update local session data state
      setSessionData({ siteIsLoggedIn, siteListingIdFound, idFromURL });

      // Step 4: Check navigation conditions based on fetched values
      if (siteListingIdFound && idFromURL) {
        // [true/false, true, true] => WelcomePage
        navigate('/WelcomePage');
      } else if (siteIsLoggedIn && !siteListingIdFound && idFromURL) {
        // [true, false, true] => Call siteLogout and redirect to /siteloginsignup
        console.log('Logging out and navigating to SiteLoginSignUp...');
        await siteLogout(); // Call the logout function
        navigate('/siteloginsignup');
      } else if (siteIsLoggedIn && (siteListingIdFound || !siteListingIdFound) && !idFromURL) {
        // [true, true/false, false] => Navigate to /admin-console
        navigate('/admin-console');
      } else {
        // [true, false, false] => Navigate to /siteloginsignup
        navigate('/SiteLoginSignUpWrapper');
      }
    };

    initializePage();
  }, [siteIsLoggedIn, fetchListingId, navigate, siteLogout]);

  // Step 5: Display values in the component
  return (
    <div>
	  {/*
      <h1>Landing Page</h1>
      <p><strong>Listing ID Found (From State):</strong> {siteListingIdFound ? "Yes" : "No"}</p>
      <p><strong>ID From URL (From State):</strong> {idFromURL ? "True" : "False"}</p>
      <p><strong>Is Logged In (From State):</strong> {siteIsLoggedIn ? "Yes" : "No"}</p>

      <p><strong>Listing ID Found (From Fetched Data):</strong> {sessionData.siteListingIdFound ? "Yes" : "No"}</p>
      <p><strong>ID From URL (From Fetched Data):</strong> {sessionData.idFromURL ? "True" : "False"}</p>
      <p><strong>Is Logged In (From Fetched Data):</strong> {sessionData.siteIsLoggedIn ? "Yes" : "No"}</p>
	  */}
    </div>
  );
};

export default LandingPage;
