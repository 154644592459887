import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styles from '../styles/AdminConsole.module.css'; // Create a CSS module for styling
import { useSiteAuth } from '../contexts/SiteAuthContext'; // Import the authentication context
import { useNavigate } from 'react-router-dom'; 
import { ListingAdminContext } from '../contexts/ListingAdminContext';


const AdminConsole = () => {
  const { siteLogout, siteUser } = useSiteAuth(); // Use siteLogout from the context
  const navigate = useNavigate(); 
	
  const { loadSiteJsonFromBackend, 
		 generateQRCode,
		 setDefaultListingId,
		 setQRCodeUrl,		 
		 listingJson, 
		 viewListings,
		 adminListings,		 
		 fetchUserListings  } = useContext(ListingAdminContext);
	
  // Use effect to fetch listings when the component mounts
  useEffect(() => {
    fetchUserListings(); // Fetch the listings from context
  }, []);	
	
  // Function to handle "Create Listing" button click
  const handleCreateListing = () => {
    navigate('/ListingAdmin');
  };	
	
  // Handle the "Manage Listing" button click
  const handleManageListing = async (listingId) => {
    try {
      // Call the backend to load the JSON for the selected listing ID
      await loadSiteJsonFromBackend(listingId);
	  console.log('LISTING_JSON', listingJson);

      // Navigate to the listing admin page once the data is loaded
      navigate('/ListingAdminMainPage');
    } catch (error) {
      console.error('Error loading the listing JSON:', error);
    }
  };	
	
  // Handle the "Generate QR Code" button click
  const handleGenerateQRCode = async (listingId) => {
    try {
      // Set the default listing ID in the context
      setDefaultListingId(listingId);

      // Load the site JSON for the listing ID
      await loadSiteJsonFromBackend(listingId);

      // Generate the QR code for the listing
      await generateQRCode(listingId);

      // Navigate to the ShowListingIDEntry component to display the QR code
      navigate('/ShowListingIDEntry');
    } catch (error) {
      console.error('Error generating QR code for listing:', error);
    }
  };	
  const handleSiteLogOut = () => {
	siteLogout();
    navigate('/LandingPage');
	  
  };
	

  return (
    <div className={styles.adminConsoleContainer}>
      {/* Top Section with User Info and Logout Button */}
      <div className={styles.topSection}>
        <h2>Welcome, {siteUser?.first_name || 'User'}!</h2>
        <button onClick={handleSiteLogOut} className={styles.logoutButton}>
          Log Out
        </button>
      </div>

      {/* View Listings Section */}
      <div className={styles.listingsSection}>
        <h2>View Listings</h2>
        <div className={styles.listingsContainer}>
          {viewListings.map((listing) => (
            <div key={listing.listing_id} className={styles.listingItem}>
              <p>{listing.listing_description || 'No description provided'}</p>
              <p><strong>Address:</strong> {listing.listing_address || 'No address provided'}</p>
              <button
                className={styles.viewButton}
                onClick={() => window.open(`https://www.openhouseaigent.com?listing_id=${listing.listing_id}`, '_blank')}
              >
                View Listing
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Admin Listings Section */}
      <div className={styles.listingsSection}>
        <h2>Admin Listings</h2>
        <div className={styles.listingsContainer}>
          {adminListings.map((listing) => (
            <div key={listing.listing_id} className={styles.listingItem}>
              <p>{listing.listing_description || 'No description provided'}</p>
              <p><strong>Address:</strong> {listing.listing_address || 'No address provided'}</p>
              <button
                className={styles.manageButton}
                onClick={() => handleManageListing(listing.listing_id)} // Call the handleManageListing function
              >
                Manage Listing
              </button>
              <button
                className={styles.manageButton}
                onClick={() => handleGenerateQRCode(listing.listing_id)} // Call the handleGenerateQRCode function
              >
                Generate QR Code
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Create Listing Button Section */}
      <div className={styles.createListingContainer}>
        <button className={styles.createListingButton} onClick={handleCreateListing}>
  		  Create Listing
   		</button>
      </div>
    </div>
  );
};

export default AdminConsole;
