import React, { useState, useContext } from 'react';
import { useSiteAuth } from '../contexts/SiteAuthContext'; // Use SiteAuthContext for authentication
import styles from '../styles/SiteLoginSignUp.module.css';
import axios from 'axios';
//import { useNavigate } from 'react-router-dom';
import { ListingAdminContext } from '../contexts/ListingAdminContext';


const SiteLoginSignUp = ({ initialView = 'login', onNavButtonClick, navButtonName }) => {
//  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState(initialView);
//  const { siteLogin, siteSignup, siteLogout, siteIsLoggedIn, siteUser } = useSiteAuth();
  // Handle Login From Site Auth Context 	
  const {siteUser,
        siteIsLoggedIn,
	    siteIsAdmin,
        siteLogin,
        siteSignup,
        siteLogout,
        siteLoading} = useSiteAuth([])
  
  const [isSiteSignUp, setIsSiteSignUp] = useState(false);
  const [isAnonSignup, setIsAnonSignup] = useState(false); // State to track anonymous signup
  const [siteIsForgotPassword, setSiteIsForgotPassword] = useState(false);
  const [siteEmail, setSiteEmail] = useState('');
  const [sitePassword, setSitePassword] = useState('');
  const [siteConfirmPassword, setSiteConfirmPassword] = useState('');
  const [siteFirstName, setSiteFirstName] = useState('');
  const [siteLastName, setSiteLastName] = useState('');
  const [sitePhone, setSitePhone] = useState('');
  const [siteRepresentedByAgent, setSiteRepresentedByAgent] = useState(''); // Default to empty string
  const [siteLoginStatus, setSiteLoginStatus] = useState('');
  const { resetListingId } = useContext(ListingAdminContext);

  // Function to generate a random string (used for anonymous user ID and password)
  const generateRandomString = (length) => {
    return Math.random().toString(36).substring(2, 2 + length);
  };

  // Utility function to validate email format
  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };	
  // Handle Enter key press to submit the form
  const handleKeyPress_SU = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default Enter key behavior
      handleSubmit(); // Call the handleSubmit function
    }
  };
	
  const handleSubmit = async () => {

	let response;
    if (isSiteSignUp) {
      // Handle normal signup
      if (sitePassword !== siteConfirmPassword) {
        setSiteLoginStatus('Passwords do not match');
        console.log('Passwords do not match'); // Print to React console
        return;
      }
	
      // Validate if the buyer's agent question is answered
      if (!siteRepresentedByAgent) {
        setSiteLoginStatus('Please indicate if you are represented by a buyer\'s agent');
        return;
      }
		
		

      // Use the siteSignup function from SiteAuthContext 
      response = await siteSignup({
        first_name: siteFirstName,
        last_name: siteLastName,
        email: siteEmail,
        phone: sitePhone,
        password: sitePassword,
        represented_by_agent: siteRepresentedByAgent,
        anon: 0 // Default to a non-anonymous user
      });

      console.log(response.message || 'Signup successful!'); // Print to console
      setSiteLoginStatus(response.message || 'Signup successful!');
    } else if (isAnonSignup) {
      if (!siteEmail && (!siteFirstName || !siteLastName)) {
        setSiteLoginStatus('Please provide either an email address or both first and last name.');
        return;
      }

      if (siteEmail && !isValidEmail(siteEmail)) {
        setSiteLoginStatus('Please enter a valid email address.');
        return;
      }
      // Validate if the buyer's agent question is answered
      if (!siteRepresentedByAgent) {
        setSiteLoginStatus('Please indicate if you are represented by a buyer\'s agent');
        return;
      }
		
		
      // Handle anonymous signup
      const anonUserId = generateRandomString(8); // Generate a random user ID
      const anonPassword = generateRandomString(12); // Generate a random password

      // Use the siteSignup function for anonymous signup
      response = await siteSignup({
        first_name: siteFirstName || 'Anonymous', // Use 'Anonymous' if no first name is provided
        last_name: siteLastName || '',
        email: siteEmail || `${anonUserId}@anon.com`, // Use a generated email if not provided
        phone: sitePhone,
        password: anonPassword, // Use the generated password
        represented_by_agent: siteRepresentedByAgent,
        anon: 1 // Indicate anonymous signup
      });

      console.log(response.message || 'Anonymous Signup successful!'); // Print to console
      setSiteLoginStatus(response.message || 'Anonymous Signup successful!');
    } else {
      // Handle normal login
      response = await siteLogin(siteEmail, sitePassword);
      console.log(response.message || 'Login successful!'); // Print to console
      setSiteLoginStatus(response.message || 'Login successful!');
    }
	  
    // Check if login/signup was successful
    if (response.success) {
      console.log('Login successful!');
      setSiteLoginStatus('Login successful!');
	  onNavButtonClick('loggedin'); 
//	  navigate('/LandingPage');
      // Pass the user and admin status to a parent or navigate
//      if (response.isAdmin) {
//        navigate('/admin-dashboard');
//      } else {
//        navigate('/welcome'); // Navigate to welcome page if not admin
//      }
    } else {
      console.log(response.message || 'Operation failed.');
      setSiteLoginStatus(response.message || 'Operation failed.');
    }	  
	  
	  
  };
	

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post('/api/siteforgotpassword', { email: siteEmail });
      setSiteLoginStatus(response.data.message);
    } catch (error) {
      setSiteLoginStatus('Error: ' + error.response.data.message);
    }
  };
  // Handle Logout
  const handleLogout = () => {
    siteLogout();
    setSiteLoginStatus('You have successfully logged out.');
  };
	
// Handle Navigation to Admin Console
const handleAdminNavigate = async () => {
  try {
    await resetListingId(); // Call the reset function from the context
	console.log('Listing ID reset successfully!');
	  
    // Call onNavButtonClick with 'admin' after the reset is complete
    if (onNavButtonClick) {
      onNavButtonClick('admin');
    }	  
  } catch (error) {
    console.error('Failed to reset the Listing ID:', error);
  }
};	

	
const handleNavButtonClick = async () => {
  try {
    if (onNavButtonClick) {
      onNavButtonClick(navButtonName);
    }	  
  } catch (error) {
    console.error('Failed to reset the Listing ID:', error);
  }
};	
	
	
  return (
    <div className={styles.siteContainer}>
      <div className={styles.siteFormBox} onKeyDown={handleKeyPress_SU}>
        {/* Render logout view if user is logged in */}
        {siteIsLoggedIn ? (
          <div>
            <h2>Welcome, {siteUser?.first_name || 'User'}!</h2>
            <p>You're currently logged in. Would you like to log out?</p>
	  
            {/* Container for Logout and Additional Navigation Button */}
            <div className={styles.navButtonContainer}>
              <button onClick={handleLogout} className={styles['back-button']}>
                Log Out
              </button>

              {/* Conditionally render navigation button if `navRoute` and `navButtonName` are provided */}
              {navButtonName && onNavButtonClick && (
                <button onClick={onNavButtonClick} className={styles['back-button']}>
                  {navButtonName}
                </button>
              )}
            </div>	  
            <div className={styles.navButtonContainer}>

              {/* Conditionally render "Admin Console" button if user is an admin */}
              {siteIsAdmin && (
                <button onClick={handleAdminNavigate} className={styles['back-button']}>
                  Admin Console
                </button>
              )}
            </div>	  
			  
          </div>
        ) : (
	  	  <>
			<h2>{siteIsForgotPassword ? 'Forgot Password' : isSiteSignUp ? 'Sign Up' : 'Login'}</h2>

			{siteIsForgotPassword ? (
			  <>
				<input
				  type="email"
				  placeholder="Enter your email to reset password"
				  value={siteEmail}
				  onChange={(e) => setSiteEmail(e.target.value)}
				  className={styles.siteInputField}
				/>
				<button onClick={handleForgotPassword} className={styles.siteSubmitButton}>Send Reset Link</button>
			  </>
			) : (
			  <>
				{isSiteSignUp || isAnonSignup ? (
				  <>
					<input
					  type="text"
					  placeholder="First Name"
					  value={siteFirstName}
					  onChange={(e) => setSiteFirstName(e.target.value)}
					  className={styles.siteInputField}
					/>
					<input
					  type="text"
					  placeholder="Last Name"
					  value={siteLastName}
					  onChange={(e) => setSiteLastName(e.target.value)}
					  className={styles.siteInputField}
					/>
				  </>
				) : null}

				<input
				  type="email"
				  placeholder="Email"
				  value={siteEmail}
				  onChange={(e) => setSiteEmail(e.target.value)}
				  className={styles.siteInputField}
				/>

				{!isAnonSignup && (
				  <input
					type="password"
					placeholder="Password"
					value={sitePassword}
					onChange={(e) => setSitePassword(e.target.value)}
					className={styles.siteInputField}
				  />					
				)}

				{isSiteSignUp && (
				  <>
					<input
					  type="password"
					  placeholder="Confirm Password"
					  value={siteConfirmPassword}
					  onChange={(e) => {
						setSiteConfirmPassword(e.target.value);
						if (sitePassword !== e.target.value) {
						  setSiteLoginStatus('Passwords do not match');
						} else {
						  setSiteLoginStatus(''); // Clear the status if they match
						}
					  }}
					  className={styles.siteInputField}
					/>
					<input
					  type="tel"
					  placeholder="Phone (optional)"
					  value={sitePhone}
					  onChange={(e) => setSitePhone(e.target.value)}
					  className={styles.siteInputField}
					/>

				  </>
				)}
				{isSiteSignUp || isAnonSignup ? (
				  <>
					<div className={styles.siteRadioGroup}>
					  <label className={styles.siteInstruction}>Are you represented by a buyer's agent?</label>
					  <div className={styles.siteRadioContainer}>
						<div className={styles.siteRadioItem}>
						  <input
							type="radio"
							id="yes"
							name="buyerAgent"
							value="yes"
							checked={siteRepresentedByAgent === 'yes'}
							onChange={() => setSiteRepresentedByAgent('yes')}
						  />
						  <label htmlFor="yes">Yes</label>
						</div>
						<div className={styles.siteRadioItem}>
						  <input
							type="radio"
							id="no"
							name="buyerAgent"
							value="no"
							checked={siteRepresentedByAgent === 'no'}
							onChange={() => setSiteRepresentedByAgent('no')}
						  />
						  <label htmlFor="no">No</label>
						</div>
					  </div>
					</div>

				  </>
				) : null}


				<div className={styles.loginmessages}>
					{siteLoginStatus && <p className={styles.siteLoginStatus}>{siteLoginStatus}</p>}
				</div>

				<button onClick={handleSubmit} className={styles.siteSubmitButton}>
				  {isAnonSignup ? 'Anonymous Sign Up' : isSiteSignUp ? 'Sign Up' : 'Login'}
				</button>

				<p className={styles.siteToggleText}>
				  {isSiteSignUp ? 'Already have an account?' : 'Need to create an account?'}{' '}
				  <span onClick={() => { setIsSiteSignUp(!isSiteSignUp); setIsAnonSignup(false); }}>
					{isSiteSignUp ? 'Login' : 'Sign Up'}
				  </span>
				</p>

				<p className={styles.siteToggleText}>
				  Want to use the site anonymously?{' '}
				  <span onClick={() => { setIsAnonSignup(!isAnonSignup); setIsSiteSignUp(false); }}>
					{isAnonSignup ? 'Go Back to Regular Sign Up' : 'Anonymous Signup'}
				  </span>
				</p>
			  </>
			)}
		  </>
		)}
	  </div>
	</div>
  );
};

export default SiteLoginSignUp;
