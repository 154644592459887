import React, { useState, useEffect, useRef  } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import styles from '../styles/BinImageQGame.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { useSwipeable } from 'react-swipeable';
import { useBinQGameImages } from '../contexts/BinQGameContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BinImageQGame = ({ 
  onAnswersSelected = () => {},
  containerHeight = '600px',
  containerWidth = '800px',
  onImageChange = () => {}, // Properly destructured and with a default value
  }) => {	
  const { loadGameData, dataLoaded, gameData,  getAnswerPercentages } = useBinQGameImages();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [userSelections, setUserSelections] = useState([]);
  const [showQuestions, setShowQuestions] = useState(true);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [userRating, setUserRating] = useState(null);
  const [overallRating, setOverallRating] = useState(null);
  const [showRatingComparison, setShowRatingComparison] = useState(false);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const intervalRef = useRef(null);
  
	
  // Function to reset the auto-scroll timer
  const resetAutoScroll = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear the existing interval
    }

    if (isAutoScrollEnabled) {
      intervalRef.current = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % gameData.length);
      }, 5000); // Restart the interval for auto-scrolling
    }
  };	
	
  useEffect(() => {
    const fetchData = async () => {
    try {
//        console.log('PRE Answer Percentages');
      const percentages = await getAnswerPercentages();
      if (percentages) {
        const { up_scaled, down_scaled } = percentages;

        // Calculate overallRating
        const total = up_scaled + down_scaled;
        if (total > 0) {
          const calculatedRating = (up_scaled / total) * 10; // Calculate the overall rating scaled between 1 and 10
          setOverallRating(calculatedRating);
        } else {
          setOverallRating(0); // If no answers, set to 0
        }
      }		  
    } catch (error) {
      console.error('Error in fetching answer percentages:', error);
      setOverallRating(0); // Default to 0 if there's an error
    }		  
  };

    fetchData();
  }, [getAnswerPercentages]);  
	
	
  // Load the data from the context when the component mounts
  useEffect(() => {
    if (!dataLoaded) {
      loadGameData('some_listing_id');
    }
  }, [dataLoaded, loadGameData]);
	
	
  // Log or operate on gameData only after it has been updated
  useEffect(() => {
    if (gameData?.length > 0) {
      const initialSelections = Array(gameData.length).fill().map(() => Array(gameData[0].questions.length).fill(null));
      setUserSelections(initialSelections);
    }
  }, [gameData]);
		

  useEffect(() => {
    const areAllQuestionsAnswered = userSelections.every(imageSelections =>
      imageSelections.every(selection => selection !== null)
    );

    setAllQuestionsAnswered(areAllQuestionsAnswered);

    if (areAllQuestionsAnswered) {
      calculateUserRating();  // Calculate user rating once all questions are answered
    }
  }, [userSelections, gameData]);		
		

  // This useEffect will trigger when currentImageIndex changes
  useEffect(() => {
    if (gameData && gameData.length > 0) {
      const imageDescription = gameData[currentImageIndex].image_description;
      if (onImageChange) {
        onImageChange(imageDescription);  // Automatically trigger image description change
      }
    }
  }, [currentImageIndex, gameData, onImageChange]);		
		

  // Handle auto-scroll using setInterval
  useEffect(() => {
    let intervalId;

    if (isAutoScrollEnabled) {
      resetAutoScroll(); // Change image every 5 seconds
    }

    // Cleanup interval on component unmount or when auto-scroll is disabled
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAutoScrollEnabled, gameData.length]);		
		
		
		
  const handleAnswer = (questionIndex, answer) => {
    const newSelections = [...userSelections];
    newSelections[currentImageIndex][questionIndex] = answer;
    setUserSelections(newSelections);
	resetAutoScroll();

    // Pass the selected question and thumbs up/down back to the parent component
    const selectedQuestion = gameData[currentImageIndex].questions[questionIndex].question;
    onAnswersSelected(selectedQuestion, answer); // Added this line to pass the question and answer back to the parent
		
		
    // After the answer is selected, check if all questions for the image are answered
    const areAllAnsweredForImage = newSelections[currentImageIndex].every(selection => selection !== null);
    if (areAllAnsweredForImage) {
      setTimeout(() => {
        handleNextImage();
      }, 300);  
    }
  };
		
		
  const handleToggleQuestions = () => {
    setShowQuestions(!showQuestions);
    setIsAutoScrollEnabled((prevState) => !prevState);		
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % gameData.length);
		
    // This passes back the Image Description back 		
    if (gameData && gameData.length > 0) {
      const imageDescription = gameData[currentImageIndex].image_description;
	}
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) => 
      (prevIndex - 1 + gameData.length) % gameData.length
    );
  };

  const calculateUserRating = () => {
    const totalQuestions = userSelections.flat().length;
    const thumbsUpCount = userSelections.flat().filter(selection => selection === 'up').length;
    const userRating = (thumbsUpCount / totalQuestions) * 10;
    setUserRating(userRating.toFixed(1));
  };

  const handleSeeRating = () => {
    if (allQuestionsAnswered) {
      if (!showRatingComparison) {
        calculateUserRating();
      }
      setShowRatingComparison(!showRatingComparison);
      setIsAutoScrollEnabled((prevState) => !prevState);		
    }
  };

  // Ensure `useSwipeable` is called unconditionally at the top
  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextImage,
    onSwipedRight: handlePreviousImage,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // Wait for the gameData to be fully loaded before rendering
  if (!dataLoaded || !gameData || gameData.length === 0) {
//    console.log("Data Loaded:", dataLoaded);
//    console.log("Game Data:", gameData);
//    console.log("Game Data Length:", gameData.length);
  
    return <div>Loading...</div>;  
  } else {
//    console.log("Data Loaded State:");
//    console.log("Data Loaded:", dataLoaded);
//    console.log("Game Data:", gameData);
//    console.log("Game Data Length:", gameData.length);
  }


  const currentImage = gameData[currentImageIndex]?.url || '';
  const currentQuestions = gameData[currentImageIndex]?.questions || [];

  // Data for the comparison chart
  const chartData = {
    labels: ['User Rating', 'Overall Rating'],
    datasets: [
      {
        label: 'Rating',
        data: [userRating !== null ? userRating : 0, overallRating !== null ? overallRating : 0], // Fallback to 0 if null
        backgroundColor: ['#007bff', '#28a745'],
      },
    ],
  };

  const options = {
    scales: {
      y: {
        min: 0,
        max: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className={styles.container}>
      <div 
        {...swipeHandlers}
        className={styles.background}
        style={{ 
          backgroundImage: `url(${currentImage})`,
          height: containerHeight,
          width: containerWidth,
        }}
      >  
        {!showRatingComparison && showQuestions && (
          <div className={styles.grid}>
            {currentQuestions.map((question, index) => (
              <div key={index} className={styles.questionContainer}>
                <p className={styles.question}>{question.question}</p>
                <div>

					<button
					  className={`${styles.iconButton} ${(userSelections[currentImageIndex] && userSelections[currentImageIndex][index] === 'up') ? styles.selected : ''}`}
					  onClick={() => handleAnswer(index, 'up')}
					>
					  <FontAwesomeIcon icon={faThumbsUp} />
					</button>
					<button
					  className={`${styles.iconButton} ${(userSelections[currentImageIndex] && userSelections[currentImageIndex][index] === 'down') ? styles.selected : ''}`}
					  onClick={() => handleAnswer(index, 'down')}
					>
					  <FontAwesomeIcon icon={faThumbsDown} />
					</button>


                </div>
              </div>
            ))}
          </div>
        )}

        {showRatingComparison && (
          <div className={styles.ratingComparison}>
            <h2>Your House Rating vs. Others</h2>
            <div className="chart-container">
              <Bar data={chartData} options={options} />
            </div>
          </div>
        )}

        {showRatingComparison ? (
          <div className={styles.hideRatingContainer}>
            <button className={styles.hideRatingButton} onClick={handleSeeRating}>
              Hide Rating
            </button>
          </div>
        ) : (
          <div className={styles.navigationContainer}>
            <div className={styles.arrowLeft} onClick={handlePreviousImage}>
              ←
            </div>
            <div className={styles.buttonGroup}>
              <button className={styles.viewImageButton} onClick={handleToggleQuestions}>
                {showQuestions ? 'View Image' : 'Show Questions'}
              </button>
              {allQuestionsAnswered && (
                <button className={styles.seeRatingButton} onClick={handleSeeRating}>
                  See Rating
                </button>
              )}
            </div>
            <div className={styles.arrowRight} onClick={handleNextImage}>
              →
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BinImageQGame;
