import axios from 'axios';
import React, { createContext, useContext, useState, useCallback } from 'react';

// Create the context
const ImageBubbleGameContext = createContext();

// Hook to use the context
export const useImageBubbleGameData = () => {
    const context = useContext(ImageBubbleGameContext);
    if (!context) {
        throw new Error('useImageBubbleGameData must be used within an ImageBubbleGameProvider');
    }
    return context;
};

// Default data if the API fails
const defaultGameData = [
  {
    listing_id: '2b78c611-af06-4449-a5b6-fb2d433faf8b',
    image_id: 'image1',
    image_file: '19-Woekel--Home-Front.gif',
    image_description: 'Front view of the home',
    image_user_prompt: '',
    image_system_prompt: '',
    location_id: 'location1',
    questions: [
      {
        question_id: 1,
        question: 'Good Price?',
        question_type: 'yn',
        question_order: 1,
      },
      {
        question_id: 2,
        question: 'Cramped?',
        question_type: 'yn',
        question_order: 2,
      },
    ],
  },
  {
    listing_id: '2b78c611-af06-4449-a5b6-fb2d433faf8b',
    image_id: 'image2',
    image_file: 'Welcome-to-Methuen.gif',
    image_description: 'Welcome to Methuen',
    image_user_prompt: '',
    image_system_prompt: '',
    location_id: 'location2',
    questions: [
      {
        question_id: 1,
        question: 'Easy Commute?',
        question_type: 'yn',
        question_order: 1,
      },
      {
        question_id: 2,
        question: 'Friendly?',
        question_type: 'yn',
        question_order: 2,
      },
    ],
  },
];

// Function to fetch images and questions from Flask API
const fetchImageBubbleGameData = async () => {
    const baseUrl = (() => {
      const hostname = window.location.hostname;
      if (hostname === 'localhost') {
        return 'http://localhost:5000/api';
      } else if (hostname === 'www.aigentTechnologies.com') {
        return 'https://www.aigentTechnologies.com/api';
      } else if (hostname === 'www.openhouseaigent.com') {
       return 'https://www.openhouseaigent.com/api';
      } else {
       return 'https://hbb-zzz.azurewebsites.net/api'; // Default URL if no match
      }
    })();

    const image_base_url = 'https://hbbreact.blob.core.windows.net/hbbblob2'; // Base URL for images

    try {
        const response = await axios.get(`${baseUrl}/get_imagebubblegame_images_questions`);
//        console.log('Fetched ImageBubbleGame data:', response.data);

        // Map through the response to build the image URLs dynamically
        return response.data.map(img => ({
            ...img,
			url: `${image_base_url}/${img.listing_id.toLowerCase()}/${img.image_file}`,
            questions: img.questions || [] // Ensure questions array exists
        }));
    } catch (error) {
        console.error('Error fetching ImageBubbleGame data:', error);
        return defaultGameData;
    }
};

// Context Provider
export const ImageBubbleGameProvider = ({ children }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [gameData, setGameData] = useState([]); // State to store fetched data

  const loadGameData = useCallback(async () => {
    try {
      const data = await fetchImageBubbleGameData();
      setGameData(data.length === 0 ? defaultGameData : data); // Store the fetched data
      setDataLoaded(true); // Set the dataLoaded flag
    } catch (error) {
      console.error('Error loading ImageBubbleGame data:', error);
      setGameData(defaultGameData); // Store default data in case of error
      setDataLoaded(true);
    }
  }, []);

  return (
    <ImageBubbleGameContext.Provider value={{ loadGameData, dataLoaded, gameData }}>
      {children}
    </ImageBubbleGameContext.Provider>
  );
};
// JavaScript Document